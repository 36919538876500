import { CapabilityProps } from "./types/power-up"
import { Trello } from "./types/trello"

const PUP_DISPLAY_NAME = "Import Power-Up"
const PUP_ID = "7"

const CAPABILITY_PROPS: CapabilityProps = {
  baseUrl: window.location.href.replace(/\/$/, ""),
  logo: {
    dark: "/static/bd-icon-dark.png",
    light: "/static/logo.png"
  },
  icon: {
    dark: "/static/bd-icon-dark.png",
    light: "/static/logo.png"
  },
  authorizeIcon: {
    dark: "/static/authorize-icon.svg",
    light: "/static/authorize-icon.svg"
  },
  upgradeIcon: {
    dark: "/static/upgrade-icon.svg",
    light: "/static/upgrade-icon.svg"
  }
}

const HELP_ACTION = {
  icon: "https://trello.bluedashersoftware.com/static/help.svg",
  alt: "help",
  position: "right" as "right" | "left",
  callback: (t: Trello.PowerUp.IFrame) => {
    return t.popup({
      title: "Help Center",
      url: "./help.html",
      height: 300
    })
  }
}

const MODAL_CONFIG = {
  title: PUP_DISPLAY_NAME,
  url: "./list-action.html",
  height: 600,
  fullscreen: false,
  actions: [HELP_ACTION]
}

const INITIAL_ROW_COUNT = 30

export {
  PUP_DISPLAY_NAME,
  PUP_ID,
  CAPABILITY_PROPS,
  HELP_ACTION,
  INITIAL_ROW_COUNT,
  MODAL_CONFIG
}
