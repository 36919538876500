/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type Trello } from "../types/trello"
import { type CapabilityProps } from "../types/power-up"
import { MODAL_CONFIG } from "../config"

export async function getBoardButton(
  t: Trello.PowerUp.IFrame,
  props: CapabilityProps
): Promise<Trello.PowerUp.BoardButtonCallback[]> {
  const icon = {
    dark: props.baseUrl + props.icon.dark,
    light: props.baseUrl + props.icon.light
  }

  const text = "Import"

  return [
    {
      icon,
      text,
      callback: (t: Trello.PowerUp.IFrame) => {
        return t.modal(MODAL_CONFIG)
      },
      condition: "edit"
    }
  ]
}
