import { MODAL_CONFIG } from "../config"
import { Trello } from "../types/trello"

export function getListActions(
  t: Trello.PowerUp.IFrame
): Trello.PowerUp.ListAction[] {
  return [
    {
      text: "Import To List",
      callback: async (tc: Trello.PowerUp.IFrame) => {
        return tc.modal(MODAL_CONFIG)
      }
    }
  ]
}
